import styled, { useTheme } from 'styled-components'
import { Button, Heading, Text } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import Link from 'next/link'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  justify-content: center;
`
const FourOhFourIcon = styled.img`
  width: 120px;
  margin: 0 0 20px;
`

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Page>
      <StyledNotFound>
        <FourOhFourIcon src="/images/stopped.png" />
        <Heading scale="xxl">404</Heading>
        <Text mb="26px">{t('Page not found.')}</Text>
        <Link href="/" passHref>
          <Button as="a" scale="sm" variant="secondary" style={{ borderColor: theme.colors.failure, color: theme.colors.failure }}>
            {t('Back Home')}
          </Button>
        </Link>
      </StyledNotFound>
    </Page>
  )
}

export default NotFound
